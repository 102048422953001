import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../../data.service';
import { AlertService } from 'src/app/alert.service';

@Component({
  selector: 'app-saccos-report',
  templateUrl: './saccos-report.component.html',
  styleUrls: ['./saccos-report.component.scss']
})
export class SaccosReportComponent implements OnInit, OnDestroy {

  rows = [];
  columns = [
    { prop: 'count', name: '#', width: 30 },
    { prop: 'sacco', name: 'sacco'},
    { prop: 'size', name: 'Members'},
    { prop: 'contact', name: 'Contact' },
    { prop: 'partner', name: 'Partner' },
    { prop: 'onboarded_on', name: 'Onboarded On' },
    { prop: 'referral_code', name: 'Referral Code' }
  ];

  subscriptions: Subscription[] = [];
  loading_data: boolean = false;
  response:any = [];
  no_data: boolean = false;

  constructor(
    private _title: Title,
    private _dataService: DataService,
    private _alertService: AlertService
  ) {
    this._title.setTitle('SACCOs Report');
    this.loading_data = true;
    this._dataService.get_saccos().subscribe(
      data => {
        this.response = data;;
          if (this.response.result_code == 1) {
            this.rows = this.response.partners;
          }else{
            //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
            this.no_data = true;
          }
      },
      error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
          this.no_data = true;
      },
      ()=>{
        this.loading_data = false;
      }
    );
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    
  }

}
