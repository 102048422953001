import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../users.service';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert.service';
import * as $ from "jquery";
import { Globals } from '../shared/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DownloadComponent } from '../download/download.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  @ViewChild('alertReset', { read: ViewContainerRef }) alertReset: ViewContainerRef;
  @ViewChild('download', { read: ViewContainerRef }) download: ViewContainerRef;

  model: any = [];
  response: any = [];
  code: any = '';

  constructor(
    private _title: Title,
    private _usersService: UsersService,
    private cfr: ComponentFactoryResolver,
    private _alertService: AlertService,
    public _global: Globals,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastr: ToastrService
    ){
      this._title.setTitle('Reset Password');
      this.model.password = '';
  }

  ngOnInit() {
    this.code = this._route.snapshot.queryParamMap.get('code');
    this.showAlert('alertReset');
    // this._alertService.info('Important: Your new password will be reset and send straight to your inbox. Sometimes, our mails may be flagged as spam, so kindly check in your sam folder too, especially if you think that the email is taking too long.');
    //console.log(this.code);
    this.model.code = this.code;
    this.reset_password();
    this.show_download();
  }

  reset_password() {
    document.getElementById('reset_btn').addEventListener('click', (e) => {
        let form = $(e.target).closest('form');
        
        //console.log(this.model);
        if(!this.model.code || this.model.code == undefined || this.model.code == ''){
          this._alertService.error('Error: Something is very wrong here, please check again the link that got you here.');
        }else if(this.model.password.length < 6){
          this._alertService.error('Error: Your password is too short. Check and try again.');
        }else if(!this.model.password || this.model.password == undefined || this.model.password == ''){
          this._alertService.error('Error: Your new password is required. Check and try again.');
        }else if(!this.model.confirm_password || this.model.confirm_password == undefined || this.model.confirm_password == ''){
          this._alertService.error('Error: Confirm your password and try again.');
        }else if(this.model.password != this.model.confirm_password){
          this._alertService.error('Error: Your passwords do not match. Check and try again.');
        }
        else{
          $('#reset_btn').prop('disabled', true);
          $('#reset_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
          this._usersService.reset_password(this.model.code, this.model.password, this.model.confirm_password).subscribe(
            data => {
              this.response = data;
                if (this.response.result_code == 200) {
                  localStorage.setItem('partners', JSON.stringify(this.response.data.user));
                  this._global.current_user = this.response.data.user;
                  this._alertService.success('Success: Your password was successfully reset. Use your new password to sign in.');
                  this._toastr.success('Your password was successfully reset.', 'Success');
                  this._router.navigate(['/']);
                  this.model = [];
                }else{
                  this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
                }
                $('#reset_btn').prop('disabled', false);
                $('#reset_btn').html('<i class="mdi mdi-shield"></i> Reset my password');
            },
            error => {
                if (error.status == 0) {
                  this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                } else {
                  this._alertService.error(error);
                }
                $('#reset_btn').prop('disabled', false);
                $('#reset_btn').html('<i class="mdi mdi-shield"></i> Reset my password');
            }
        );

        }
        e.preventDefault();return;
    });
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
  
  show_download(target: any = 'download') {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(DownloadComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
