import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Globals } from './shared/globals';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _url: any = "";
  private _api_key: any = "";
  private _request_id: any = "";
  private _request: any = "";

  constructor(
      private cfr: ComponentFactoryResolver,
      private http: Http,
      private _global: Globals
      ) {

      this._url = this._global.url;
      this._api_key = this._global.api_key;
      this._request_id = this._global.request_id;
  }

  update_profile() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/resources/get_partner_resources', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  download(id: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          resource_id: id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/resources/download_resource', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_partners() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/partners/get_list_of_partners', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_saccos() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/saccos/get_onboarded_saccos', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_my_saccos() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/saccos/get_my_saccos', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_partner_dashboard_statistics() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/partners/get_partner_dashboard_statistics', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_admin_dashboard_statistics() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/partners/get_admin_dashboard_statistics', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_finance_dashboard_statistics(){
    this._request = {
      request:{
        request_id: this._request_id,
        api_key:this._api_key,
        data: {
          user_id:this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/finance/finance_admin_statistics', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_pending_withdrawal_requests() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/finance/pending_payment_requests', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());


  }

  get_banks() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/banks/banks', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_bank_branch(bank_id: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          bank_id: bank_id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/bank_branches/bank_branches', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_payment_methods() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/accounts/payment_options', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  create_payment_method(data: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          payment_method: data.payment_option,
          mobile_money_provider_id: data.mobile_money_provider_id,
          bank_id: data.bank,
          bank_branch_id: data.branch,
          account_number: data.account_number,
          account_name: data.account_name
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/accounts/create', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_mobile_money_providers() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/mobile_money_providers/mobile_money_providers', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  delete_payment_method(account: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          account_id: account
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/accounts/delete', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_partner_earnings() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/earnings/get_partner_earnings', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  request_withdrawal(account_id: any, amount: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          account_id: account_id,
          amount: amount
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/withdrawals/request_withdrawal', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  create_resource(data: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          title: data.name
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/resources/create_resource', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_professions() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/professions/get_professions', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_users() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          title: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/users/get_all_users', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  create_application_profile(data: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          application_name: data.name,
          protocol: data.protocol,
          url: data.url,
          sender_id: data.sender_id
        }
      }
    };
    console.log(this._request)
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/application_profiles/create', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

  get_all_application_profiles() {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
          title: this._global.current_user.id
        }
      }
    };
    // tslint:disable-next-line: max-line-length
    return this.http.post(this._url + '/api/application_profiles/get_all_application_profiles', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
  }

}
