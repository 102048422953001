import { Injectable, ComponentFactoryResolver } from '@angular/core';
import {Md5} from 'ts-md5/dist/md5';
import { environment } from "../../environments/environment";
import { md5 } from "../md5";
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';

@Injectable()
export class Globals {

    readonly url: string = environment.api.url;
    readonly api_key: string = environment.api.key;
    readonly request_id: number = environment.api.id;

    public asset: any = environment.app_assets;

    current_user: any = [];
    signed_in: boolean = false;
    content_loading: boolean = false;
    
    constructor(
        private _md5: Md5,
        private cfr: ComponentFactoryResolver,
        private _toastr: ToastrService,
        private _title: Title,
        ){
        
        this.current_user = JSON.parse(localStorage.getItem('partners'));

        
    }
    
    generate_avatar(email: string, size: any){
        return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=mp';
    }


    encrypt(str){
        if(!environment.encrypt_requests){
            return str;
        }
        var encrypted = "";
        for (var i=0; i<str.length; i++){
            var a = str.charCodeAt(i);
            var b = a ^ 12;
            encrypted = encrypted + String.fromCharCode(b);
        }
        return encrypted;
    }

    copy_to_clipboard(text: string) {
        var textArea = document.createElement("textarea");
        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try{
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
            document.body.removeChild(textArea);
            // console.log('Copying text command was ' + msg);
            return true;
        }catch(err){
            // console.log('Oops, unable to copy');
            return false;
        }
    }

    md5_string(original: any){
        // return this._md5.appendStr(original).end().toString();
        let e = md5(original);
        return e;
    }

    share(option: string, url: string){
        var current_url = url;
        var title = 'Automate all your ' + (this.asset.flavor == 'chamasoft')?'Chama':'SACCO' + ' operations today!';
        var via = "chamasoft";
        if(option == 'linkedin'){
            window.open("https://www.linkedin.com/shareArticle?url=" +  encodeURIComponent(current_url) + "&title=" +  title, "_blank");
        }else if(option == 'twitter'){
            window.open("http://www.twitter.com/intent/tweet?url=" + encodeURIComponent(current_url) + "&via=" + via + "&text=" +  title, "_blank");
        }else if(option == 'facebook'){
            window.open("https://www.facebook.com/sharer/sharer.php?u=" +  encodeURIComponent(current_url), "_blank");
        }else if(option == 'whatsapp'){
            window.open("https://wa.me/?text=" + encodeURIComponent(current_url), "_blank");
        }else if(option == 'copy'){
            if(this.copy_to_clipboard(current_url)){
                this._toastr.success('The link has been copied successfully.', 'Copied!');
            }else{
                this._toastr.error('An error ocurred copying the link.', 'Error');
            }
        }else if(option == 'email'){
            window.open("mailto:?subject=" + encodeURIComponent(title) + "&body=" + encodeURIComponent("Hey, you really have to check this out " + current_url + "\n\n\n"), "_top");
        }else if(option == 'pocket'){
            window.open("https://getpocket.com/save?url=" + encodeURIComponent(current_url) + "&title=" + title, "_blank");
        }
    }

    download(url: string){
        window.open(url, '_self');
    }

    theme_app(){
        if(this.asset.flavor == 'websacco'){ 
            $('head')
            .append('<meta name="theme-color" content="#006e14">')
            .append('<link rel="icon" type="image/x-icon" href="assets/images/favicon.png"/>')
            .append('<link rel="manifest" href="websacco-manifest.webmanifest">');
            this._title.setTitle('WebSacco Partner Program');
        }
        if(this.asset.flavor == 'chamasoft'){ 
            $('head')
            .append('<meta name="theme-color" content="#00abf2">')
            .append('<link rel="icon" type="image/x-icon" href="assets/images/chamasoft_favicon.png"/>')
            .append('<link rel="manifest" href="websacco-manifest.webmanifest">');
            this._title.setTitle('Chamasoft Partner Program');
        }
        $('.navbar').removeClass('chamasoft, websacco').addClass(this.asset.flavor);
        $('body').removeClass().addClass(this.asset.flavor);
    }
}
