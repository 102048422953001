import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Http, Response } from "@angular/http";
import { Globals } from './shared/globals';
import 'rxjs/Rx';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

    private _url: any = "";
    private _api_key: any = "";
    private _request_id: any = "";
    private _request: any = "";

    constructor(
        private cfr: ComponentFactoryResolver,
        private http: Http,
        private _global: Globals
        ){
        
        this._url = this._global.url;
        this._api_key = this._global.api_key;
        this._request_id = this._global.request_id;

    }
    
    check_session_token() {
        this._request = {
          request:{
            request_id: this._request_id,
            api_key: this._api_key,
            data: {
              session_token: this.get_session_token()
            }
          }
        };
        return this.http.post(this._url + '/api/users/check_session_token', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    private get_session_token() {
        let current_user = JSON.parse(localStorage.getItem('partners'));
        //console.log(current_user);
        if (current_user == undefined) {
            return "";
        } else {
            if (current_user.session_token == undefined) {
                return "";
            } else {
                return current_user.session_token;
            }
        }
    }

  get_all_users(data: any) {
    this._request = {
      request: {
        request_id: this._request_id,
        api_key: this._api_key,
        data: {
          user_id: this._global.current_user.id,
        }
      }
    };
      // tslint:disable-next-line: max-line-length
      return this.http.post(this._url + '/api/users/get_all_users', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    get_user(user_id: any, logins: number = 0) {
      this._request = {
        request: {
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id,
            logins_count: logins
          }
        }
      };
      return this.http.post(this._url + '/api/users/get_user', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    add_user(data: any) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: data
      };
      return this.http.post(this._url + '/add_user', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    block_user(user_id: any) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id
          }
      };
      return this.http.post(this._url + '/block_user', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    unblock_user(user_id: any) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id
          }
      };
      return this.http.post(this._url + '/unblock_user', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    update_user_auth(user_id: any, auth: string) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id,
            auth: auth
          }
      };
      return this.http.post(this._url + '/update_user_auth', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    update_user_email(user_id: any, email: string) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id,
            email: email
          }
      };
      return this.http.post(this._url + '/update_user_email', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    update_user_info(user_id: any, current_user_id: any, first_name: string, last_name: string) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id,
            current_user_id: current_user_id,
            first_name: first_name,
            last_name: last_name
          }
      };
      return this.http.post(this._url + '/update_user_info', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    update_user_password(user_id: any, password: string) {
      this._request = {
          id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: user_id,
            password: password
          }
      };
      return this.http.post(this._url + '/update_user_password', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    sign_in(email: any, password: string) {
      this._request = {
        request:{
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            identity: email,
            password: password
          }
        }
      };
      return this.http.post(this._url + '/api/users/login', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

    sign_out() {
        localStorage.removeItem('partners');
    }
    
    reset_password_request(email: any) {
      this._request = {
        request:{
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            email: email
          }
        }
      };
      return this.http.post(this._url + '/api/users/forgot_password', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }
    
    reset_password(code: any, password: any, confirm_password: any) {
      this._request = {
        request:{
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            code: code,
            password: password,
            confirm_password: confirm_password,
          }
        }
      };
      return this.http.post(this._url + '/api/users/reset_password', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }
    
    update_password(current_password: string, new_password: string, confirm_password: string) {
      this._request = {
        request:{
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: this._global.current_user.id,
            password_details: {
              old_password: current_password,
              new_password: new_password,
              confirm_password: confirm_password
            }
          }
        }
      };
      return this.http.post(this._url + '/api/users/update_password', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }
    
    update_profile(first_name: string, last_name: string, email: string) {
      this._request = {
        request:{
          request_id: this._request_id,
          api_key: this._api_key,
          data: {
            user_id: this._global.current_user.id,
            user_details: {
              first_name: first_name,
              last_name: last_name,
              email: email
            }
          }
        }
      };
      return this.http.post(this._url + '/api/users/update_profile', this._global.encrypt(JSON.stringify(this._request))).map((response: Response) => response.json());
    }

}
