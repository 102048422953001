import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Globals } from "../shared/globals";

@Component({
    selector: 'app-download',
    templateUrl: './download.component.html'
})

export class DownloadComponent implements OnInit {

    constructor( 
        private _toastr: ToastrService,
        private _global: Globals
    ) {}

    ngOnInit() {}

    download_app(app: string,){
        var url = '';
        if(this._global.asset.flavor == 'chamasoft'){
            url = 'https://partners.chamasoft.com/downloads/';
            if(app == 'win-64'){ 
                url = url + 'ChamasoftPPx64.exe'; 
            }else if(app == 'win-32'){ 
                url = url + 'ChamasoftPPx86.exe'; 
            }else if(app == 'deb'){ 
                url = url + 'ChamasoftPPx64.deb'; 
            }else{ 
                url = ''; 
            }
        }else{
            url = 'https://partners.websacco.com/downloads/';
            if(app == 'win-64'){ 
                url = url + 'WebSaccoPPx64.exe'; 
            }else if(app == 'win-32'){ 
                url = url + 'WebSaccoPPx86.exe'; 
            }else if(app == 'deb'){ 
                url = url + 'WebSaccoPPx64.deb'; 
            }else{ 
                url = ''; 
            }
        }
        
        if(url == ''){
            this._toastr.success("Hey, unfortunately,<br>we don't have that download at the moment.","Oops!");
        }else{
            window.open(url, '_self');
            this._toastr.success('Your download is starting shortly...<br>Thank you!', "Download");
        }
    }
}