import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { Router } from '@angular/router';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-signout',
  templateUrl: './signout.component.html',
  styleUrls: ['./signout.component.scss']
})
export class SignoutComponent implements OnInit {

  constructor(
    private _userService: UsersService,
    private _router: Router,
    private _global: Globals
  ) {
    
  }

  ngOnInit() {
    this._userService.sign_out();
    this._global.current_user = [];
    this._global.signed_in = false;
    this._router.navigate(['/signin']);
  }

}
