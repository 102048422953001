import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { Globals } from '../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../alert.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['../app.component.scss', './dashboard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, OnDestroy {

  dashboard_data: any = [];
  payment_withdrawal_data: any = [];
  subscriptions: Subscription[] = [];
  referral_code = '';
  loading_data = false;
  response: any = [];
  no_data = false;
  assets: any = {};


  constructor(
    private _title: Title,
    private _data: DataService,
    public globals: Globals,
    private _toastr: ToastrService,
    private _alertService: AlertService
  ) {
    this.assets = environment.app_assets;

    this._title.setTitle('Dashboard');
    this.referral_code = this.globals.current_user.invite_code;
    // tslint:disable-next-line: triple-equals
    if (this.globals.current_user.auth == 'partner') {
      this.get_partner_dashboard_statistics();
    }
    // tslint:disable-next-line: triple-equals
    if (this.globals.current_user.auth == 'admin') {
      this.get_admin_dashboard_statistics();
    }

    // tslint:disable-next-line: triple-equals
    if (this.globals.current_user.auth == 'finance') {
      this.get_finance_dashboard_statistics();
      this.get_pending_withdrawal_requests();
    }
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.forEach(e => e.unsubscribe());
  }

  get_partner_dashboard_statistics() {
    this.loading_data = true;
    this._data.get_partner_dashboard_statistics().subscribe(
      data => {
        this.response = data;
        // console.log(this.response);
        // tslint:disable-next-line: triple-equals
        if (this.response.result_code == 1) {
          this.dashboard_data = this.response.statistics;
        } else {
          // this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        // tslint:disable-next-line: triple-equals
        if (error.status == 0) {
          // tslint:disable-next-line: max-line-length
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      () => {
        this.loading_data = false;
      }
    );
  }

  get_admin_dashboard_statistics() {
    this.loading_data = true;
    this._data.get_admin_dashboard_statistics().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        // tslint:disable-next-line: triple-equals
        if (this.response.result_code == 1) {
          this.dashboard_data = this.response.statistics;
        }else{
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          // tslint:disable-next-line: max-line-length
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      () => {
        this.loading_data = false;
      }
    );
  }

  get_finance_dashboard_statistics(){
    this.loading_data = true;
    this._data.get_finance_dashboard_statistics().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 1) {
          this.dashboard_data = this.response.statistics;
        } else {
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      () => {
        this.loading_data = false;
      }
    );
  }

  get_pending_withdrawal_requests() {
    this.loading_data = true;
    this._data.get_pending_withdrawal_requests().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 1) {
          this.payment_withdrawal_data = this.response.statistics;
        } else {
          this.no_data = true;
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      () => {
        this.loading_data = false;
      }
    );
  }

}
