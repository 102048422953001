export const environment = {
  production: false,
  app_name: "Chamasoft Partner Portal (Development Mode)",
  api: {
    url: 'https://partners-api.websacco.com',
    key: '5gf5hYx4tmSsgeVz8KPnvEhaWkDc1f4vKhZ6DkYP',
    id: 1020
  },
  encrypt_requests: false,
  app_assets: {
    flavor: 'chamasoft',
    logo: "assets/images/chamasoft_logo.png",
    logo_mini: "assets/images/chamasoft_favicon.png",
    navbar_background: 'linear-gradient(120deg, #00abf273, #00abf2)',
  }
};
