import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from './users.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import * as $ from "jquery";
import { Globals } from './shared/globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private _title: Title,
    private _router: Router,
    private _global: Globals
    ){
    this._title.setTitle('Loading...');
  }

  ngOnInit(){
    this._global.theme_app();
    this._router.events.subscribe((route) => {
      if (route instanceof NavigationStart) {
        this.setLoading(true);//.log('Loading start');
      }
      if (route instanceof NavigationEnd) {
        this.setLoading(false);//console.log('Loading end');
      }
    },error=>{
      console.log('An error occured: ' + error);
    });
  }

  setLoading(enable) {
    let shell = $('.loader_shell');
    if (enable) {
        //this._global.content_loading = true;
        $(shell).addClass('loader');
    } else {
        //this._global.content_loading = false;
        $(shell).removeClass('loader');
    }
  }

}
