import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { UsersService } from '../users.service';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {

  model: any = [];
  response:any = [];
  _full_name_array: any = [];

  constructor(
    private _title: Title,
    private toastr: ToastrService,
    private _router: Router,
    private _usersService: UsersService,
    private _global: Globals
  ) {
    this._title.setTitle('New User');
  }

  ngOnInit() {
    
  }

  add_user(){
    $('#add_user_btn').prop('disabled',true);
    $('#add_user_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
    if(!(this.model.name) || this.model.name.trim() == ''){
      this.toastr.error('The name for the user is required.', 'User Name Required');
      $('#add_user_btn').prop('disabled',false);
      $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
    }else if(!(this.model.email) || this.model.email.trim() == ''){
      this.toastr.error('Email for the user is required.', 'Email Required');
      $('#add_user_btn').prop('disabled',false);
      $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
    }
    else{
      let first_name = '', last_name = '', middle_name = '';
      if (typeof this.model.name !== 'undefined') {
          this._full_name_array = this.model.name.split(" ", 3);

          if (typeof this._full_name_array[0] == 'undefined') {
              first_name = '';
          } else {
              first_name = this._full_name_array[0];
          }

          if (typeof this._full_name_array[1] !== 'undefined' && typeof this._full_name_array[2] !== 'undefined') {
              middle_name = this._full_name_array[1];
              last_name = this._full_name_array[2];
          } else {
              if (typeof this._full_name_array[1] !== 'undefined') {
                  middle_name = '';
                  last_name = this._full_name_array[1];
              } else {
                  middle_name = '';
                  last_name = '';
              }
          }
      }
      //this can be removed if a column for middle_name is added on the db
      if(last_name == ''){
        last_name = middle_name;
      }
      
      let data: any = {
        'first_name': first_name,
        'last_name': last_name,
        'email': this.model.email
      };
      this._usersService.add_user(data).subscribe(
        data => {
          this.response = data;
          if (this.response.response_code == '200') {
            //console.log(this.response.data);
            $('#add_user_btn').prop('disabled',false);
            $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
            this.toastr.success('User added successfully!', 'Success');
            setTimeout(() => this._router.navigate(['/users']), 1000);
          }
          else if (this.response.response_code == 'PMC_005') {
            this.toastr.error('Sorry, seems like this user already exists!', 'User Exists');
            $('#add_user_btn').prop('disabled',false);
            $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
          }
          else if (this.response.response_code == 'PMC_004') {
            this.toastr.error('Sorry, we are unable to add the user, try again later', 'Error occured');
            $('#add_user_btn').prop('disabled',false);
            $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
          }
          else if (this.response.response_code == 'PMC_012') {
            $('#add_user_btn').prop('disabled',false);
            $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
            this.toastr.info('User added successfully, but could not reach out to them through email. Please inform the admin to take action.', 'Mail error occured');
            setTimeout(() => this._router.navigate(['/users']), 1000);
          }
          else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
            $('#add_user_btn').prop('disabled',false);
            $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
          }
        },
        error => {
          if (error.status == 0) {
            this.toastr.warning('You are currently not connected to the internet. Check your internet settings.', 'No Internet');
          } else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
          }
          $('#add_user_btn').prop('disabled',false);
          $('#add_user_btn').html('<i class="mdi mdi-check"></i> Add User');
        }
      );
    }
    
  }

}
