import { Component, OnInit } from '@angular/core';
import { Globals } from '../shared/globals';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  
  public samplePagesCollapsed = true;
  avatar: string = '';

  is_page_loading: boolean = true;

  constructor(
    public _global: Globals,
    private _users: UsersService
  ) {

  }

  ngOnInit() {
    this.is_page_loading = false;
    setTimeout(() => {
      this.avatar = (this._global.current_user != undefined)? this._global.generate_avatar(this._global.current_user.email, '37') : '';
      $("#usr_avatar").attr("src", this.avatar);
    }, 600);
  }
  

}
