import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';
import { Globals } from '../shared/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-professions',
    templateUrl: './professions.component.html',
    styleUrls: ['./professions.component.scss']
})
export class ProfessionsComponent implements OnInit, OnDestroy {

    is_page_loading: boolean = true;
    no_data: boolean = false;
    response: any = [];
    professions: any = [];
    current_user_id: number;
    model: any = {};

    constructor(
        private _title: Title,
        private _alertService: AlertService,
        private _dataService: DataService,
        public _global: Globals,
        private cfr: ComponentFactoryResolver,
        private _route: ActivatedRoute,
        private _router: Router,
        private _toastr: ToastrService
    ) {
        this._title.setTitle('Professions');

    }

    ngOnInit() {
        this.get_all_professions();
        this.model.name = ''; 
    }

    ngOnDestroy() {

    }

    get_all_professions() {
        this.no_data = false;
        this.is_page_loading = true;
        this._dataService.get_professions().subscribe(
            data => {
                this.response = data;;
                if (this.response.result_code == 1) {
                    this.professions = this.response.professions;
                } else {
                    // this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
                    this.no_data = true;
                }
            },
            error => {
                if (error.status == 0) {
                    // tslint:disable-next-line: max-line-length
                    this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                } else {
                    this._alertService.error(error);
                }
                this.no_data = true;
            },
            () => {
                this.is_page_loading = false;
            }
        );
    }

    edit(id: any){
        console.log(id)
    }

    show_profession_form() {
        $('.add_profession').slideDown();
        $('.display_profession').hide();
    }


}
