import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from './shared/globals';
import {Md5} from 'ts-md5/dist/md5';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { AccountComponent } from './account/account.component';
import { UsersComponent } from './users/users.component';
import { SettingsComponent } from './settings/settings.component';
import { SignoutComponent } from './signout/signout.component';
import { SigninComponent } from './signin/signin.component';
import { HttpModule, BaseRequestOptions } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { UsersService } from './users.service';
import { AlertComponent } from './alert/alert.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { NewUserComponent } from './new-user/new-user.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { GoogleChartsModule } from 'angular-google-charts';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ForgotComponent } from './forgot/forgot.component';
import { EarningsComponent } from './earnings/earnings.component';
import { ResourcesComponent } from './resources/resources.component';
import { SaccosComponent } from './saccos/saccos.component';
import { PartnersReportComponent } from './reports/partners-report/partners-report.component';
import { SaccosReportComponent } from './reports/saccos-report/saccos-report.component';
import { OnboardComponent } from './onboard/onboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { DownloadComponent } from './download/download.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ProfessionsComponent } from './professions/professions.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ApplicationProfileComponent } from './application-profiles/application-profiles.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
    DashboardComponent,
    AnalyticsComponent,
    NotificationsComponent,
    AccountComponent,
    UsersComponent,
    SettingsComponent,
    SignoutComponent,
    SigninComponent,
    AlertComponent,
    ManageUserComponent,
    NewUserComponent,
    ForgotComponent,
    EarningsComponent,
    ResourcesComponent,
    SaccosComponent,
    PartnersReportComponent,
    SaccosReportComponent,
    OnboardComponent,
    ResetPasswordComponent,
    DownloadComponent,
    PaymentMethodComponent,
    ProfessionsComponent,
    ApplicationProfileComponent  
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    FormsModule,
    NgbModule.forRoot(),
    HttpModule,
    HttpClientModule,
    BrowserModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      resetTimeoutOnDuplicate: true,
      extendedTimeOut: 1000,
      enableHtml: true,
      progressBar: false,
      // progressAnimation: 'increasing',
      toastClass: 'toastr',
      tapToDismiss: true
    }),
    GoogleChartsModule.forRoot(),
    NgxDatatableModule,
    SelectDropDownModule
  ],
  providers: [
    Globals,
    Md5,
    UsersService,
    BaseRequestOptions,
    {provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ AlertComponent, DownloadComponent ]
})
export class AppModule { }