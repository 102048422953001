import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { Globals } from '../shared/globals';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-onboard',
  templateUrl: './onboard.component.html',
  styleUrls: ['./onboard.component.scss']
})
export class OnboardComponent implements OnInit, OnDestroy {

  referral_code: string = '';
  assets: any = {};

  constructor(
    private _title: Title,
    public globals: Globals
  ) {
    this._title.setTitle('Onboard SACCO');
    this.referral_code = this.globals.current_user.invite_code;
    this.assets = environment.app_assets;
  }

  ngOnInit() {
    
  }

  ngOnDestroy(){
    
  }

}
