import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../users.service';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../alert.service';
import * as $ from "jquery";
import { Globals } from '../shared/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { DownloadComponent } from '../download/download.component';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent implements OnInit {

  @ViewChild('alertForgot', { read: ViewContainerRef }) alertForgot: ViewContainerRef;
  @ViewChild('download', { read: ViewContainerRef }) download: ViewContainerRef;

  model: any = [];
  response: any = [];
  goto: any = '';

  constructor(
    private _title: Title,
    private _usersService: UsersService,
    private cfr: ComponentFactoryResolver,
    private _alertService: AlertService,
    public _global: Globals,
    private _route: ActivatedRoute,
    private _router: Router
    ){
    this._title.setTitle('Forgot Password');
  }

  ngOnInit() {
    //this.goto = this._route.snapshot.queryParamMap.get('goto');
    this.reset_password();
    this.show_download();
  }

  reset_password() {
    document.getElementById('reset_btn').addEventListener('click', (e) => {
        let form = $(e.target).closest('form');
        
        var email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        
        this.showAlert('alertForgot');
        //console.log(this.model);
        if(!this.model.email || this.model.email == undefined || this.model.email == ''){
          this._alertService.error('Error: Your email is required.');
        }
        else if(!email_regex.test(this.model.email)){
          this._alertService.error('Error: The email provided is invalid.');
        }
        else{
          $('#reset_btn').prop('disabled', true);
          $('#reset_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
          this._usersService.reset_password_request(this.model.email).subscribe(
            data => {
              this.response = data;
                if (this.response.result_code == '4') {
                  this._alertService.success('Success: We have sent you an email. Check the inbox at ' + this.model.email + ' for more details.');
                  //this.goto = this._route.snapshot.queryParamMap.get('goto') || '/dashboard';
                  //this._router.navigate([this.goto]);
                  this.model = [];
                }else {
                  //incorrect details
                  this._alertService.error("Error: We couldn't process your request at this time. Check your email and try again. If this persists, feel free to contact us for support.");
                }
                $('#reset_btn').prop('disabled', false);
                $('#reset_btn').html('<i class="mdi mdi-shield"></i> Reset password');
            },
            error => {
                if (error.status == 0) {
                  this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                } else {
                  this._alertService.error(error);
                }
                $('#reset_btn').prop('disabled', false);
                $('#reset_btn').html('<i class="mdi mdi-shield"></i> Reset password');
            }
        );

        }
        e.preventDefault();return;
    });
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }
  
  show_download(target: any = 'download') {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(DownloadComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
