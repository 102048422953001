import { Component, OnInit, OnDestroy, ComponentFactoryResolver, ViewChild, ViewContainerRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { Globals } from '../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent implements OnInit, OnDestroy {

  @ViewChild('alertPayment', { read: ViewContainerRef }) alertPayment: ViewContainerRef;

  my_payment_options: any [];
  payment_methods: any = [];
  banks: any = [];
  mobile_money_providers: any = [];
  branches: any = [];
  payment_config: any = {
    multiple: false,
    search:true,
    height: '200px',
    placeholder:'Select payment method',
    noResultsFound: 'No results found!',
    searchPlaceholder:'Search here...'
  };
  banks_config: any = {
    multiple: false,
    search:true,
    height: '200px',
    placeholder:'Select bank',
    noResultsFound: 'No results found!',
    searchPlaceholder:'Search here...'
  };
  branches_config: any = {
    multiple: false,
    search:true,
    height: '200px',
    placeholder:'Select bank branch',
    noResultsFound: 'No results found!',
    searchPlaceholder:'Search here...'
  };
  mobile_provider_config: any = {
    multiple: false,
    search:true,
    height: '200px',
    placeholder:'Select mobile provider',
    noResultsFound: 'No results found!',
    searchPlaceholder:'Search here...'
  };
  payment_state: boolean = false;
  banks_state: boolean = false;
  branches_state: boolean = false;
  account_name_state: boolean = false;
  account_number_state: boolean = false;
  email_state: boolean = false;
  phone_state: boolean = false;
  mobile_provider_state: boolean = false;

  email: string = '';
  phone_number: string = '';
  payment_method: any = '';
  account_name: string = '';
  account_number: any = '';
  bank: number;
  branch: number;
  payment_option: number;
  mobile_money_provider: any;

  method: string = '';
  
  subscriptions: Subscription[] = [];
  loading_data: boolean = false;
  response:any = [];

  constructor(
    private _title: Title,
    private _data: DataService,
    public globals: Globals,
    private _toastr: ToastrService,
    private _alertService: AlertService,
    private cfr: ComponentFactoryResolver
  ) {
    this._title.setTitle('Payment Method');
    this.get_payment_methods();
    this.get_banks();
    this.get_mobile_money_providers();
  }

  reset(){
    this.method = '';
    this.payment_state = false;
    this.banks_state = true;
    this.branches_state = true;
    this.account_name_state = true;
    this.account_number_state = true;
    this.email_state = true;
    this.phone_state = true;
    this.mobile_provider_state = true;
    this.email = this.globals.current_user.email;
    this.phone_number = this.globals.current_user.phone;
    this.account_name = this.globals.current_user.first_name + ' ' + this.globals.current_user.last_name;
    this.payment_method = '';
    this.account_number = '';
    this.branch = 0;
    this.payment_option = 0;
    this.bank = 0;
    this.mobile_money_provider = 0;
    $('.bank, .branch, .account_name, .account_number, .phone_number, .email, .mobile_provider').hide();
  }

  ngOnInit() {
    this.showAlert('alertPayment');
  }

  ngOnDestroy(){
    
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

  get_banks(){
    this.loading_data = true;
    this._data.get_banks().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 200) {
          this.banks = this.response.data.banks;
        }else{
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      ()=>{
        this.loading_data = false;
      }
    );
  }

  get_mobile_money_providers(){
    // this.loading_data = true;
    this._data.get_mobile_money_providers().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 200) {
          this.mobile_money_providers = this.response.data.mobile_money_providers;
        }else{
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      ()=>{
        // this.loading_data = false;
      }
    );
  }

  get_payment_methods(){
    this.loading_data = true;
    this._data.get_payment_methods().subscribe(
      data => {
        this.response = data;
        //console.log(this.response);
        if (this.response.result_code == 1) {
          this.payment_methods = this.response.payment_options;
          this.my_payment_options = this.response.accounts;
        }else{
          //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
        }
      },
      error => {
        if (error.status == 0) {
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
      },
      ()=>{
        this.loading_data = false;
      }
    );
  }

  select_payment(option: any){
    this.reset();
    try {
      this.payment_option = option.value.id;
      //console.log(option.value.id)
      if(option.value.id == 1){
        //mobile money
        this.method = 'mobile';
        $('.account_name, .phone_number, .mobile_provider').slideDown();
        this.account_name_state = false;
        this.phone_state = false;
        this.mobile_provider_state = false;
      }else if(option.value.id == 2){
        //bank
        this.method = 'bank';
        $('.bank, .branch, .account_name, .account_number').slideDown();
        this.banks_state = false;
        //this.branches_state = false;
        this.account_name_state = false;
        this.account_number_state = false;
      }else if(option.value.id == 3){
        //paypal
        this.method = 'paypal';
        $('.account_name, .email').slideDown();
        this.account_name_state = false;
        this.email_state = false;
      }
    } catch (error) {
      this.payment_option = 0;
    }
  }

  toggle_add_payment(state: string){
    this.reset();
    if(state == 'show'){
      $('#add_new_payment_method').slideUp();
      $('.add_payment').slideDown();
    }else if(state == 'hide'){
      $('#add_new_payment_method').slideDown();
      $('.add_payment').slideUp();
    }
  }

  select_bank(option: any){
    this.branches_state = false;
    this.branches = [];
    try {
      this.bank = option.value.id;
      //console.log(option.value.id)
      if(option.value.id >= 1){
        this._data.get_bank_branch(option.value.id).subscribe(
          data => {
            this.response = data;
            //console.log(this.response);
            if (this.response.result_code == 200) {
              this.branches = this.response.data.bank_branches;
            }else{
              //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
            }
          },
          error => {
            if (error.status == 0) {
              this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
            } else {
              this._alertService.error(error);
            }
          },
          ()=>{
            this.branches_state = false;
          }
        );
        this.bank = option.value.id;
      }
    }catch(err){
      this.branches_state = true;
      this.bank = 0;
    }
  }

  select_branch(option: any){
    try {
      //console.log(option.value.id)
      this.branch = option.value.id;
    } catch (error) {
      this.branch = 0
    }
  }

  select_provider(option: any){
    try {
      //console.log(option.value.id)
      this.mobile_money_provider = option.value.id;
    } catch (error) {
      this.mobile_money_provider = 0
    }
  }

  create_account(){
    let data: any = {};
    let acc_number: any = '';
    if(this.method == 'mobile'){
      acc_number = $('#phone_number').val();
    }else if(this.method == 'bank'){
      acc_number = $('#account_number').val();
    }else if(this.method == 'paypal'){
      acc_number = $('#email').val();
    }
    var acc_name = $('#account_name').val();
    data.payment_option = this.payment_option;
    data.mobile_money_provider_id = this.mobile_money_provider;
    data.bank = this.bank;
    data.branch = this.branch;
    data.account_number = acc_number;
    data.account_name = acc_name;
    //var account_number = /^\d+$/;
    var email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // if(!account_number.test(this.account_number) && this.method == 'bank'){
    //   this._alertService.error('Error: The account number provided is invalid.');
    // }else 
    if(this.method == 'mobile' && this.mobile_money_provider == 0){
      this._alertService.error('Error: The mobile money provider is required. Check and try again.');
    }else if(this.method != 'paypal' && acc_name == ''){
      this._alertService.error('Error: The account name is required. Check and try again.');
    }else if(acc_number == ''){
      this._alertService.error('Error: The account number is required. Check and try again.');
    }else{console.log(data);
      $('#save_btn').prop('disabled', true);
      $('#save_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
      this._data.create_payment_method(data).subscribe(
        data => {
          this.response = data;
            if (this.response.result_code == 1) {
              this._alertService.success('Success: You\'ve successfully added a payment method.');
              this.my_payment_options = this.response.accounts;
              this.toggle_add_payment('hide');
            }else if (this.response.result_code == '0'){
              //incorrect details
              this._alertService.error("Sorry, can't add that payment method. You already have an account with the same account number.");
            }else {
              //incorrect details
              this._alertService.error("Error: We couldn't process your request at this time. You can try again.");
            }
            $('#save_btn').prop('disabled', false);
            $('#save_btn').html('<i class="mdi mdi-content-save"></i> Save details');
        },
        error => {
            if (error.status == 0) {
              this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
            } else {
              this._alertService.error(error);
            }
            $('#save_btn').prop('disabled', false);
            $('#save_btn').html('<i class="mdi mdi-content-save"></i> Save details');
        }
      );
    }
  }

  delete_method(id: any, i:any){
    $('#delete_method_' + i).prop('disabled', true);
    $('#delete_method_' + i).html('<i class="fa fa-circle-o-notch fa-spin"></i> Delete...');
    this._data.delete_payment_method(id).subscribe(
      data => {
        this.response = data;
          if (this.response.result_code == 1) {
            this._alertService.success('Success: You\'ve successfully deleted the payment method.');
            this.my_payment_options = this.response.accounts;
            this.toggle_add_payment('hide');
          }else if (this.response.result_code == '0'){
            //incorrect details
            this._alertService.error("Sorry, can't delete that payment method. Try again.");
          }else {
            //incorrect details
            this._alertService.error("Error: We couldn't process your request at this time. You can try again.");
          }
          $('#delete_method_' + i).prop('disabled', false);
          $('#delete_method_' + i).html('<i class="mdi mdi-close"></i> Delete');
      },
      error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
          $('#delete_method_' + i).prop('disabled', false);
          $('#delete_method_' + i).html('<i class="mdi mdi-close"></i> Delete');
      }
    );
  }

}
