import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UsersService } from './users.service';
import { Globals } from './shared/globals';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _router: Router, 
    private _usersService: UsersService,
    private _global: Globals
    ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    
      let current_user = JSON.parse(localStorage.getItem('partners'));
      if(current_user){
        return this._usersService.check_session_token().map(
            data => {
                //console.log(data);
                if (data !== null) {
                    // logged in so return true
                    if (data.result_code == 200) {
                        //console.log(current_user.user);
                        this._global.signed_in = true;
                        return true;
                    }
                    else {
                        this._global.signed_in = false;
                        this._router.navigate(['/signin'], { queryParams: { goto: state.url } });
                        this.setLoading(false);
                        return false;
                    }
                }
                else {
                    this._global.signed_in = false;
                    this._router.navigate(['/signin'], { queryParams: { goto: state.url } });
                    this.setLoading(false);
                    return false;
                }
            },
            error => {
                this._global.signed_in = false;
                this._router.navigate(['/signin'], { queryParams: { goto: state.url } });
                this.setLoading(false);
                return false;
            });
      }
      else{
        this._global.signed_in = false;
        this._router.navigate(['/signin'], { queryParams: { goto: state.url } });
        this.setLoading(false);
        return false;
      }

  }

  setLoading(enable) {
    let shell = $('.loader_shell');
    if (enable) {
        //this._global.content_loading = true;
        $(shell).addClass('loader');
    } else {
        //this._global.content_loading = false;
        $(shell).removeClass('loader');
    }
  }

}
