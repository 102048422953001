import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../users.service';
import { DataService } from '../data.service';
import { AlertService } from '../alert.service';
import { ToastrService } from 'ngx-toastr';
import { Globals } from '../shared/globals';
@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  is_page_loading: boolean = true;
  no_data: boolean = false;
  response:any = [];
  users:any = [];
  current_user_id: number;

  constructor(
    private _title: Title,
    private _usersService: UsersService,
    private _dataService: DataService,
    private _alertService: AlertService,
    private toastr: ToastrService,
    private _global: Globals
  ) {
    this.current_user_id = this._global.current_user.id;
    this._title.setTitle('Manage Users');
  }

  ngOnInit() {
    this.get_all_users();
    //setTimeout(() => this.toastr.info('Welcome to <em>Campaigns</em>!', 'Welcome'));
    
  }

  get_all_users() {
    this.no_data = false;
    this.is_page_loading = true;
    this._dataService.get_users().subscribe(
      data => {
        this.response = data;
        if (this.response.result_code == 1) {
          this.users = this.response.users;
        } else {
          // this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
          this.no_data = true;
        }
      },
      error => {
        if (error.status == 0) {
          // tslint:disable-next-line: max-line-length
          this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
        } else {
          this._alertService.error(error);
        }
        this.no_data = true;
      },
      () => {
        this.is_page_loading = false;
      }
    );
  }


}
