import { Component, OnInit, OnDestroy } from '@angular/core';
import { ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DataService } from '../data.service';
import { Subscription } from 'rxjs';
import { Globals } from '../shared/globals';
import { ToastrService } from 'ngx-toastr';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
    selector: 'app-application-profile',
    templateUrl: './application-profiles.component.html',
    styleUrls: ['../app.component.scss', './application-profiles.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class ApplicationProfileComponent implements OnInit, OnDestroy {

    referral_code = '';
    loading_data = false;
    response: any = [];
    is_page_loading: boolean = true;
    no_data = false;
    model: any = {};
    current_user_id: number;
    cfr: any;
    application_profiles: any = [];

    constructor(
        private _title: Title,
        private _dataService: DataService,
        public _global: Globals,
        private _toastr: ToastrService,
        private _alertService: AlertService
    ) {
        this._title.setTitle('Application Profiles');
        this.referral_code = this._global.current_user.invite_code;
    }

    ngOnInit() {
        this.get_all_application_profiles();
    }

    ngOnDestroy() {

    }

    get_all_application_profiles() {
        this.no_data = false;
        this.is_page_loading = true;
        this._dataService.get_all_application_profiles().subscribe(
            data => {
                this.response = data;;
                if (this.response.result_code == 1) {
                    this.application_profiles = this.response.profiles;
                } else {
                    //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
                    this.no_data = true;
                }
            },
            error => {
                if (error.status == 0) {
                    // tslint:disable-next-line: max-line-length
                    this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                } else {
                    this._alertService.error(error);
                }
                this.no_data = true;
            },
            () => {
                this.is_page_loading = false;
            }
        );
    }

    show_application_profile_form() {
        $('.add_application_profiles').slideDown();
        $('.display_profiles').hide();
        $('.opps_block').hide();
        $('#show_profile_toggle_btn').slideDown();
        $('#profile_add_btn').hide();

    }

    show_profiles(){
        $('.add_application_profiles').hide();
        $('.opps_block').hide();
        $('.display_profiles').slideDown();
        $('#show_profile_toggle_btn').hide();
        $('#profile_add_btn').slideDown();
    }

    add_application_profile() {
        console.log(this.model);
        if (this.model.name == undefined || this.model.name == '') {
            this._toastr.error('Application  name is required, please review and try again.', 'Oops!');
            //this._alertService.error('Application  name is required, please review and try again.');
        } else if(this.model.sender_id == undefined || this.model.sender_id == '') {
            this._toastr.error('Sender id  name is required, please review and try again.', 'Oops!');
            // this._alertService.error('Sender id  name is required, please review and try again.');
        } else if (this.model.protocol == undefined || this.model.protocol == '') {
            this._toastr.error('Protocol name is required, please review and try again.', 'Oops!');
            // this._alertService.error('Protocol name is required, please review and try again.');
        } else if (this.model.url == undefined || this.model.url == '') {
            this._toastr.error('Application Url name is required, please review and try again.', 'Oops!');
            // this._alertService.error('Application Url name is required, please review and try again.');
        }  else {
            $('#add_application_profile_btn').prop('disabled', true);
            $('#add_application_profile_btn').html('<i class="fa fa-spinner fa-spin mr-1"></i> Processing...');
            this._dataService.create_application_profile(this.model).subscribe(
                data => {
                    this.response = data;
                    if (this.response.result_code == 1) {
                        this.get_all_application_profiles();
                    } else {
                        this._toastr.error('Error: We encountered an error while processing your request. Please try again.');
                    }
                },
                error => {
                    // tslint:disable-next-line: triple-equals
                    if (error.status == 0) {
                        // tslint:disable-next-line: max-line-length
                        this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                    } else {
                        this._alertService.error(error);
                    }
                }, () => {
                    $('#add_application_profile_btn').prop('disabled', false);
                    $('#add_application_profile_btn').html('<i class="mdi mdi-content-save mr-1"></i> Save');
                }
            );
        }
    }

}
