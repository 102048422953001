import { Component, OnInit } from '@angular/core';
import { environment } from "../../environments/environment";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  current_year = new Date().getFullYear();
  app_name = environment.app_name;

  constructor() { }

  ngOnInit() {
  }

}
