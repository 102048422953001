import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { AccountComponent } from './account/account.component';
import { SettingsComponent } from './settings/settings.component';
import { SignoutComponent } from './signout/signout.component';
import { SigninComponent } from './signin/signin.component';
import { UsersComponent } from './users/users.component';
import { AuthGuard } from './auth.guard';
import { NotificationsComponent } from './notifications/notifications.component';
import { ManageUserComponent } from './manage-user/manage-user.component';
import { NewUserComponent } from './new-user/new-user.component';
import { ForgotComponent } from './forgot/forgot.component';
import { EarningsComponent } from './earnings/earnings.component';
import { ResourcesComponent } from './resources/resources.component';
import { SaccosComponent } from './saccos/saccos.component';
import { SaccosReportComponent } from './reports/saccos-report/saccos-report.component';
import { PartnersReportComponent } from './reports/partners-report/partners-report.component';
import { OnboardComponent } from './onboard/onboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { ProfessionsComponent } from './professions/professions.component';
import { ApplicationProfileComponent } from './application-profiles/application-profiles.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'analytics', component: AnalyticsComponent, canActivate: [AuthGuard] },
  { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'users/new', component: NewUserComponent, canActivate: [AuthGuard] },
  { path: 'users/:id', component: ManageUserComponent, canActivate: [AuthGuard] },
  { path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard] },
  { path: 'earnings', component: EarningsComponent, canActivate: [AuthGuard] },
  { path: 'earnings/payment-methods', component: PaymentMethodComponent, canActivate: [AuthGuard] },
  { path: 'application-profiles', component: ApplicationProfileComponent, canActivate: [AuthGuard] },
  { path: 'resources', component: ResourcesComponent, canActivate: [AuthGuard] },
  { path: 'my-saccos', component: SaccosComponent, canActivate: [AuthGuard] },
  { path: 'reports/saccos', component: SaccosReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/partners', component: PartnersReportComponent, canActivate: [AuthGuard] },
  { path: 'onboard-sacco', component: OnboardComponent, canActivate: [AuthGuard] },
  { path: 'signout', component: SignoutComponent },
  { path: 'signin', component: SigninComponent },
  { path: 'forgot-password', component: ForgotComponent },
  { path: 'professions', component: ProfessionsComponent, canActivate: [AuthGuard] },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: '**', redirectTo: '/dashboard' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
