import { Component, OnInit } from '@angular/core';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import { Globals } from '../shared/globals';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  providers: [NgbDropdownConfig]
})
export class NavbarComponent implements OnInit {
  public sidebarOpened = false;
  avatar: string = '';

  toggleOffcanvas() {
    this.sidebarOpened = !this.sidebarOpened;
    if (this.sidebarOpened) {
      document.querySelector('.sidebar-offcanvas').classList.add('active');
    }
    else {
      document.querySelector('.sidebar-offcanvas').classList.remove('active');
    }
  }

  constructor(
    config: NgbDropdownConfig,
    public _global: Globals
    ){
    config.placement = 'bottom-right';
  }

  ngOnInit() {
    // setTimeout(() => {
      this.avatar = (this._global.current_user != undefined)? this._global.generate_avatar(this._global.current_user.email, '37') : '';
      $("#usr_avatar_hdr").attr("src", this.avatar);
    // }, 10000);
  }

}
