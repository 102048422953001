import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../users.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent implements OnInit {

  is_page_loading: boolean = true;
  no_data: boolean = false;
  response:any = [];
  user_data:any = [];
  user_logins:any = [];
  user_id:any = '';

  constructor(
    private _title: Title,
    private _usersService: UsersService,
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._title.setTitle('Manage User');
  }

  ngOnInit() {
    this.user_id = this._route.snapshot.paramMap.get('id');
    this.get_user_info();
  }

  get_user_info() {
    this.no_data = false;
    this.is_page_loading = true;
    this._usersService.get_user(this.user_id, 3).subscribe(
        data => {
          this.response = data;
            if (this.response.response_code == '1') {
              //console.log(this.response.data);
              this.user_data = this.response.user;
              // this.toastr.info('Hey, we found some data for you. Wait a little while as we populate it.', 'Psst');
            // tslint:disable-next-line: triple-equals
            } else if (this.response.response_code == '401') {
              this.no_data = true;
            } else {

            }
            this.is_page_loading = false;
        },
        error => {
            if (error.status == 0) {

            } else {

            }
            this.is_page_loading = false;
        }
    );
  }

  toggle_all_records(type: string, count: number){
    if(type == 'logins') {
      if(count==0) {
        $('#show_all_activity_btn').html('Processing...');
        $('#show_all_activity_btn').prop('disabled',true);
      }
      // tslint:disable-next-line: triple-equals
      else if(count == 5) {
        $('#show_few_activity_btn').html('Processing...');
        $('#show_few_activity_btn').prop('disabled',true);
      }
      this._usersService.get_user(this.user_id, count).subscribe(
        data => {
          this.response = data;
          if (this.response.response_code == '200') {
            //console.log(this.response.data);
            this.user_logins = this.response.data.logins;
            if(count==0){
              $('#show_all_activity_btn').fadeOut('fast');
              $('#show_all_activity_btn').html('Show all activity');
              $('#show_all_activity_btn').prop('disabled',false);
              $('#show_few_activity_btn').fadeIn('fast');
            }
            else if(count==5){
              $('#show_few_activity_btn').fadeOut('fast');
              $('#show_few_activity_btn').html('Show few activity');
              $('#show_few_activity_btn').prop('disabled',false);
              $('#show_all_activity_btn').fadeIn('fast');
            }
          }
          else if (this.response.response_code == 'PMC_011') {
            this.toastr.error('Sorry, we did not find anything, try refreshing the page', 'Data not found');
            if(count==0){
              $('#show_all_activity_btn').html('Show all activity');
              $('#show_all_activity_btn').prop('disabled',false);
            }
            else if(count==5){
              $('#show_few_activity_btn').html('Show all activity');
              $('#show_few_activity_btn').prop('disabled',false);
            }
          }else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
            if(count==0){
              $('#show_all_activity_btn').html('Show all activity');
              $('#show_all_activity_btn').prop('disabled',false);
            }
            else if(count==5){
              $('#show_few_activity_btn').html('Show all activity');
              $('#show_few_activity_btn').prop('disabled',false);
            }
          }
        },
        error => {
          if (error.status == 0) {
            this.toastr.warning('You are currently not connected to the internet. Check your internet settings.', 'No Internet');
          } else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
          }
          if(count==0){
            $('#show_all_activity_btn').html('Show all activity');
            $('#show_all_activity_btn').prop('disabled',false);
          }
          else if(count==5){
            $('#show_few_activity_btn').html('Show all activity');
            $('#show_few_activity_btn').prop('disabled',false);
          }
        }
      );
    }
  }

  toggle_block(user_id:number, toggle: string){
    $('#block_unblock_btn').html('<i class="fa fa-spinner fa-spin"></i> Processing...');
    $('#block_unblock_btn').prop('disabled',true);
    if(toggle == 'block'){
      this._usersService.block_user(user_id).subscribe(
        data => {
          this.response = data;
          if (this.response.response_code == '200') {
            //console.log(this.response.data);
            $('#block_unblock_btn').html('<i class="fa fa-unlock"></i> Unblock User');
            $('#block_unblock_btn').removeClass('btn-inverse-danger').addClass('btn-inverse-primary');
            $('#block_unblock_btn').prop('disabled',false);
            this.toastr.success('User blocked successfully!', 'Success');
            setTimeout(() => this._router.navigate(['/users']), 1000);
          }
          else if (this.response.response_code == 'CS_USR_006') {
            this.toastr.error('Sorry, seems like this user does not exist!', 'User not found');
            $('#block_unblock_btn').html('<i class="fa fa-lock"></i> Block User');
            $('#block_unblock_btn').prop('disabled',false);
          }
          else if (this.response.response_code == 'CS_USR_012') {
            this.toastr.error('Sorry, we are unable to block this user!', 'Unable to block user');
            $('#block_unblock_btn').html('<i class="fa fa-lock"></i> Block User');
            $('#block_unblock_btn').prop('disabled',false);
          }
          else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
            $('#block_unblock_btn').html('<i class="fa fa-lock"></i> Block User');
            $('#block_unblock_btn').prop('disabled',false);
          }
        },
        error => {
          if (error.status == 0) {
            this.toastr.warning('You are currently not connected to the internet. Check your internet settings.', 'No Internet');
          } else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
          }
          $('#block_unblock_btn').html('<i class="fa fa-lock"></i> Block User');
          $('#block_unblock_btn').prop('disabled',false);
        }
      );
    }
    else if(toggle == 'unblock'){
      this._usersService.unblock_user(user_id).subscribe(
        data => {
          this.response = data;
          if (this.response.response_code == '200') {
            //console.log(this.response.data);
            $('#block_unblock_btn').html('<i class="fa fa-lock"></i> Block User');
            $('#block_unblock_btn').removeClass('btn-inverse-primary').addClass('btn-inverse-danger');
            $('#block_unblock_btn').prop('disabled',false);
            this.toastr.success('User unblocked successfully!', 'Success');
            setTimeout(() => this._router.navigate(['/users']), 1000);
          }
          else if (this.response.response_code == 'CS_USR_006') {
            this.toastr.error('Sorry, seems like this user does not exist!', 'User not found');
            $('#block_unblock_btn').html('<i class="fa fa-unlock"></i> Unblock User');
            $('#block_unblock_btn').prop('disabled',false);
          }
          else if (this.response.response_code == 'CS_USR_012') {
            this.toastr.error('Sorry, we are unable to unblock this user!', 'Unable to unblock user');
            $('#block_unblock_btn').html('<i class="fa fa-unlock"></i> Unblock User');
            $('#block_unblock_btn').prop('disabled',false);
          }
          else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
            $('#block_unblock_btn').html('<i class="fa fa-unlock"></i> Unblock User');
            $('#block_unblock_btn').prop('disabled',false);
          }
        },
        error => {
          if (error.status == 0) {
            this.toastr.warning('You are currently not connected to the internet. Check your internet settings.', 'No Internet');
          } else {
            this.toastr.error('Sorry, we encountered an unknown error, try refreshing the page', 'Error occured');
          }
          $('#block_unblock_btn').html('<i class="fa fa-unlock"></i> Unblock User');
          $('#block_unblock_btn').prop('disabled',false);
        }
      );
    }
    
  }

}
