import { Component, OnInit, OnDestroy, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { DataService } from '../data.service';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';
import { Globals } from '../shared/globals';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent implements OnInit, OnDestroy {

  is_page_loading: boolean = true;
  no_data: boolean = false;
  response:any = [];
  resources:any = [];
  current_user_id: number;
  model: any = {};

  constructor(
    private _title: Title,
    private _alertService: AlertService,
    private _dataService: DataService,
    public _global: Globals,
    private cfr: ComponentFactoryResolver,
    private _route: ActivatedRoute,
    private _router: Router,
    private _toastr: ToastrService
  ) {
    this._title.setTitle('Resources');
    
  }

  ngOnInit() {
    this.get_all_resources();
    this.model.name = '';
    //this.add_resource();
  }

  ngOnDestroy(){
    
  }

  get_all_resources() {
    this.no_data = false;
    this.is_page_loading = true;
    this._dataService.update_profile().subscribe(
      data => {
        this.response = data;;
          if (this.response.result_code == 1) {
            this.resources = this.response.resources;
          }else{
            //this._alertService.error('Error: We encountered an error while processing your request. Please try again.');
            this.no_data = true;
          }
      },
      error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
          this.no_data = true;
      },
      ()=>{
        this.is_page_loading = false;
      }
    );
  }
  // this.resources = [
  //   {id: 1, resource_title: 'Getting started with WEBSACCO', file_name: 'getting_started.pdf', file_size: '6.2 MB', file_extension: 'pdf', mime_type: 'application/pdf', downloads: '89,781', resource_url: 'https://partners.websacco.com/resources/getting_started.pdf', downloaded: true},
  // ]

  download(id: any) {
    $('#download_' + id).prop('disabled', true);
    $('#download_' + id).html('<i class="fa fa-circle-o-notch fa-spin"></i> Download');
    this._dataService.download(id).subscribe(
      data => {
        this.response = data;
        if (this.response.result_code == 1) {
          var obj_key = this.findWithAttr(this.resources, 'id', this.response.resources.id);
          if(obj_key != -1){
            this.resources[obj_key] = this.response.resources;
          }
          this._global.download(this.response.resources.link);
          this._toastr.success("<i class='fa fa-check'></i> Your download will begin in a moment...", "", {timeOut:3000});
        }else{
          this._toastr.success("Oops, seems like we can't download this file for you at the moment.<br>Try again later.", "Error");
          $('#download_' + id).prop('disabled', false);
          $('#download_' + id).html('<i class="fa fa-download"></i> Download');
        }
      },
      error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
      },
      ()=>{
        $('#download_' + id).prop('disabled', false);
        $('#download_' + id).html('<i class="fa fa-download"></i> Download');
      }
    );
  }


  findWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
      if(array[i][attr] === value) {
        return i;
      }
    }
    return -1;
  }

  add_resource() { console.log('kjgfdfds');
    // this.showAlert('alertAddResource');
    if (!this.model.name || this.model.name == undefined || this.model.name == '') {
      this._toastr.error('Error: Resource name is required.');
    }else{
      $('#add_resource_btn').prop('disabled', true);
      $('#add_resource_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
      this._dataService.create_resource(this.model).subscribe(
        data => {
          this.response = data;
          if (this.response.status == 1) {
            this._alertService.success("Resource successfully created.");
            this.model = {};
          } else {
            this._alertService.error("Resource could not be created . Please try again.");
          }
        },
        error => {
          if (error.status == 0) {
            this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
          } else {
            this._alertService.error(error);
          }
        }, () => {
          $('#add_resource_btn').prop('disabled', false);
          $('#add_resource_btn').html('<i class="mdi mdi-content-save"></i> Save');
        }
      );
    }
  }

  show_resource_form(){
    $('.add_resource').slideDown();
    $('.display_resource').hide();
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
