import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UsersService } from '../users.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { Globals } from '../shared/globals';
import { AlertService } from '../alert.service';
import { AlertComponent } from '../alert/alert.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @ViewChild('alertUpdateProfile', { read: ViewContainerRef }) alertUpdateProfile: ViewContainerRef;
  @ViewChild('alertChangePassword', { read: ViewContainerRef }) alertChangePassword: ViewContainerRef;

  is_page_loading: boolean = true;
  no_data: boolean = false;
  response:any = [];
  user_data:any = [];
  user_logins:any = [];
  user_id:any = '';
  model:any = {};
  password_model:any = {};

  constructor(
    private _title: Title,
    private _usersService: UsersService,
    private toastr: ToastrService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _globals: Globals,
    private cfr: ComponentFactoryResolver,
    private _alertService: AlertService
  ) {
    this._title.setTitle('My Profile');
    this.password_model.new_password = '';
  }

  ngOnInit() {
    this.user_id = this._globals.current_user.id;
    this.user_data = this._globals.current_user;
    this.model.first_name = this._globals.current_user.first_name;
    this.model.last_name = this._globals.current_user.last_name;
    this.model.email = this._globals.current_user.email;
    this.model.phone = this._globals.current_user.phone;
    this.update_profile();
    this.change_password();
  }

  show_update_profile(){
    $('.update_profile').slideDown();
    $('.update_password').hide();
    $('#update_profile_toggle_btn').slideUp();
    $('#update_password_toggle_btn').slideDown();
  }

  show_update_password(){
    $('.update_password').slideDown();
    $('.update_profile').hide();
    $('#update_password_toggle_btn').slideUp();
    $('#update_profile_toggle_btn').slideDown();
  }

  update_profile() {
    document.getElementById('save_btn').addEventListener('click', (e) => {
        let form = $(e.target).closest('form');
        var email_regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        this.showAlert('alertUpdateProfile');
        //console.log(this.model);
        if(!this.model.first_name || this.model.first_name == undefined || this.model.first_name == ''){
          this._alertService.error('Error: Your first_name is required.');
        }else if(!this.model.last_name || this.model.last_name == undefined || this.model.last_name == ''){
          this._alertService.error('Error: Your last_name is required.');
        }else if(!this.model.email || this.model.email == undefined || this.model.email == ''){
          this._alertService.error('Error: Your email is required.');
        }
        else if(!email_regex.test(this.model.email)){
          this._alertService.error('Error: The email provided is invalid.');
        }
        else{
          $('#save_btn').prop('disabled', true);
          $('#save_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
          this._usersService.update_profile(this.model.first_name, this.model.last_name, this.model.email).subscribe(
            data => {
              this.response = data;
                if (this.response.result_code == 1) {
                  //console.log(this.response);
                  localStorage.setItem('partners', JSON.stringify(this.response.data));
                  this._globals.current_user = this.response.data;
                  this._alertService.success('Success: You updated your profile successfully.');
                  //this.model = [];
                }else{
                  //incorrect details
                  this._alertService.error('Error: An error occurred while updating your profile. Please try again.');
                }
                $('#save_btn').prop('disabled', false);
                $('#save_btn').html('<i class="mdi mdi-content-save"></i> Save');
            },
            error => {
                if (error.status == 0) {
                  this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
                } else {
                  this._alertService.error(error);
                }
                $('#save_btn').prop('disabled', false);
                $('#save_btn').html('<i class="mdi mdi-content-save"></i> Save');
            }
        );

        }
        e.preventDefault();return;
    });
  }

  change_password() {
    document.getElementById('change_password_btn').addEventListener('click', (e) => {
        let form = $(e.target).closest('form');
        this.showAlert('alertChangePassword');
        //console.log(this.model);
        if(!this.password_model.current_password || this.password_model.current_password == undefined || this.password_model.current_password == ''){
          this._alertService.error('Error: Your current password is required.');
        }else if(this.password_model.new_password.length < 7 ){
          this._alertService.error('Error: Your new password is too short.');
        }else if(!this.password_model.new_password || this.password_model.new_password == undefined || this.password_model.new_password == ''){
          this._alertService.error('Error: Your new password is required.');
        }else if(this.password_model.new_password != this.password_model.confirm_new_password){
          this._alertService.error('Error: Your passwords do not match.');
        }else{
          $('#change_password_btn').prop('disabled', true);
          $('#change_password_btn').html('<i class="fa fa-circle-o-notch fa-spin"></i> Processing...');
          this._usersService.update_password(this.password_model.current_password,this.password_model.new_password, this.password_model.confirm_new_password).subscribe(
            data => {
              this.response = data;
              console.log(this.response)
              if (this.response.result_code == 1) {
                localStorage.setItem('partners', JSON.stringify(this.response.data.user));
                this._globals.current_user = this.response.data.user;
                //console.log(this._global.current_user);
                this._alertService.success('Success: Your password was successfully changed.');
                this.password_model = [];
              }else if(this.response.result_code == '8'){
                //incorrect details
                this._alertService.error('Error: Your current password is incorrect.');
              }else{
                this._alertService.error('Error: We are unable to change your password. Please try again.');
              }
              $('#change_password_btn').prop('disabled', false);
              $('#change_password_btn').html('<i class="mdi mdi-content-save"></i> Change Password');
            },
            error => {
              if (error.status == 0) {
                this._alertService.error('We seem to be having an issue with your internet connection. Make sure you are connected to the internet and try again.');
              } else {
                this._alertService.error(error);
              }
              $('#change_password_btn').prop('disabled', false);
              $('#change_password_btn').html('<i class="mdi mdi-content-save"></i> Change Password');
            }
        );

        }
        e.preventDefault();return;
    });
  }

  showAlert(target) {
    this[target].clear();
    let factory = this.cfr.resolveComponentFactory(AlertComponent);
    let ref = this[target].createComponent(factory);
    ref.changeDetectorRef.detectChanges();
  }

}
